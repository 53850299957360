import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import { State } from '@/store/models';
import Navbar from '@/components/common/navbar/Navbar.vue';
import Footer from '@/components/common/footer/Footer.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';

@Component({
  components: {
    Navbar,
    Footer,
    BannerLegal,
  },
})

export default class Account extends Vue {
  @StateClass investor!: State['investor'];

  /**
   * Returns container classes, depending
   * on current page.
   *
   * @returns {array}
   */
  get containerClasses(): string[] {
    const classes: string[] = ['account__content'];

    // No container for help center
    if (this.$route.path !== '/account/help') {
      classes.push('container');
    }

    return classes;
  }
}
