import { render, staticRenderFns } from "./AccountEarnings.html?vue&type=template&id=2145105e&scoped=true&"
import script from "./AccountEarnings.ts?vue&type=script&lang=ts&"
export * from "./AccountEarnings.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_investments.scss?vue&type=style&index=0&id=2145105e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2145105e",
  null
  
)

export default component.exports