import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { State as StateClass, Getter, Action } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { Payment } from '@/store/models/investment';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import { State } from '@/store/models';
import AccountInfobox from '@/components/account/infobox/AccountInfobox.vue';
import AccountBalance from '@/components/account/balance/AccountBalance.vue';
import AccountDividends from '@/components/account/dividends/AccountDividends.vue';
import AccountCompensation from '@/components/account/compensation/AccountCompensation.vue';
import AccountFunds from '@/components/account/funds/AccountFunds.vue';
import Support from '@/components/common/support/Support.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import { collections } from '@/vue';
import { Asset } from '@/store/models/asset';
import { Investor } from '@/store/models/user';
import { intercom } from '../../../../whitelabel.config';

@Component({
  components: {
    AccountInfobox,
    AccountBalance,
    AccountDividends,
    AccountCompensation,
    AccountFunds,
    Support,
  },
})
export default class AccountDashboard extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.dashboard.title').toString()),
    };
  }

  // Biding collection to the instance
  collections = collections;

  intercom = intercom;

  showUpgradeInfobox = false;
  handleSupport = false;

  @StateClass idin!: State['idin'];
  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass identificationRequest!: State['identificationRequest'];

  @Getter getInvestedAssets!: Asset[];
  @Getter getNotInvestedAssets!: Asset[];
  @Getter isInvestor!: boolean;
  @Getter isEligibleToInvest!: Function;
  @Getter getOpenPayments!: Payment[];

  @Action openModal!: Function;

  mounted(): void {
    this.upgradeInfoboxHandle();
  }

  @Watch('user')
  onInvestorChange(newUser: Investor, oldUser: Investor): void {
    if (!oldUser && newUser) {
      this.upgradeInfoboxHandle();
    }
  }

  @Watch('isInvestor')
  onIdentifiedChange(): void {
    this.upgradeInfoboxHandle();
  }

  get identificationStatus(): string {
    if (this.isInvestor) {
      return 'approved';
    }

    const customIdent = this.identificationRequest;
    if (customIdent) {
      if (customIdent.status === 'initial') {
        return 'pending';
      }
      if (customIdent.status === 'rejected') {
        return 'rejected';
      }
    }

    return 'initial';
  }

  get notFullNotInvestedAssets(): Asset[] {
    return this.getNotInvestedAssets;
  }

  get investedAssets(): Asset[] {
    return this.getInvestedAssets;
  }

  get pendingIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Initial;
  }

  get rejectedIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Rejected;
  }

  get pendingPayments(): Payment[] {
    return this.getOpenPayments;
  }

  upgradeInfoboxHandle(): void {
    const showUpgradeInfobox = localStorage.getItem('showUpgradeInfobox');
    this.showUpgradeInfobox = !this.isInvestor && showUpgradeInfobox ? JSON.parse(showUpgradeInfobox) : !!this.user && !this.isInvestor;
  }

  dismissUpgradeInfobox(): void {
    localStorage.setItem('showUpgradeInfobox', 'false');
    this.showUpgradeInfobox = false;
  }
}
