import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { Investment, InvestmentEarning } from '@/store/models/investment';
import { capitalize } from '@/filters/string';

@Component({
  components: {
    Loader,
    WithDownloads,
    LazyImage,
  },
})

export default class AccountEarnings extends Vue {
  @Prop({}) investment!: Investment;
  @Prop({}) earnings!: InvestmentEarning[];

  get earningsData(): any {
    return this.earnings.map((earning): any => ({
      date: earning.paymentDateTime,
      amount: earning.amount,
    }));
  }

  earningsColumns = ['date', 'amount'];

  earningsOptions = {
    headings: {
      date: capitalize(this.$t('common.date').toString()),
      amount: capitalize(this.$t('common.amount').toString()),
    },
    filterable: false,
    perPage: 12,
    perPageValues: [],
    sortable: ['date', 'amount'],
    // columnsClasses strings need to have a space at the end
    // because vue-tables-2 adds classes runtime without a space before
    columnsClasses: {
      date: 'table__col table__col--date ',
      amount: 'table__col table__col--amount ',
    },
    skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    texts: {
      count: this.$t('dashboard.table.count'),
      first: this.$t('dashboard.table.first'),
      last: this.$t('dashboard.table.last'),
      filter: this.$t('dashboard.table.filter'),
      filterPlaceholder: this.$t('dashboard.table.filterPlaceholder'),
      limit: this.$t('dashboard.table.limit'),
      page: this.$t('dashboard.table.page'),
      noResults: this.$t('dashboard.table.earnings.noResults'),
      filterBy: this.$t('dashboard.table.filterBy'),
      loading: this.$t('dashboard.table.loading'),
      defaultOption: this.$t('dashboard.table.defaultOption'),
      columns: this.$t('dashboard.table.columns'),
    },
  };
}
