import { render, staticRenderFns } from "./AccountTrees.html?vue&type=template&id=7af3df66&scoped=true&"
import script from "./AccountTrees.ts?vue&type=script&lang=ts&"
export * from "./AccountTrees.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_investments.scss?vue&type=style&index=0&id=7af3df66&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af3df66",
  null
  
)

export default component.exports