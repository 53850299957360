import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, State } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import to from 'await-to-js';
import { saveAs } from 'file-saver';
import { AxiosResponse, AxiosError } from 'axios';
import { State as StateClass } from '@/store/models';
import { firebaseAxiosInstance } from '@/axios';
import Loader from '@/components/common/loader/Loader.vue';
import { Payment, PaymentStatus } from '@/store/models/investment';

@Component({
  components: {
    Loader,
  },
})

export default class AccountBalance extends Vue {
  @Prop({ })
  investmentsPromise!: Promise<any>;

  @Getter getSharesTotalInvested!: number;
  @Getter getTotalInvested!: number;

  @Action(addToastMessage) addToastMessage!: Function;

  @State('payments') payments!: StateClass['payments'];

  isGeneratingPDF = false;
  selectedYear: number | null = null;
  firstPaymentsYear: number | null = null;

  // Determines the number of years shown in the tax statement year selector
  yearsToShow: number = 2;

  @Watch('payments', { immediate: true }) onPaymentsChange(newPayments: Payment[]): void {
    if (newPayments.length) {
      const firstPayment = this.payments
        .filter(({ type, providerData }): boolean => type !== 'gift-purchase' && providerData.status === PaymentStatus.Paid)
        .sort((a, b): number => (a.paymentDateTime || a.createdDateTime).toMillis() - (b.paymentDateTime || b.createdDateTime).toMillis())[0];

      if (firstPayment.paymentDateTime) {
        this.firstPaymentsYear = firstPayment.paymentDateTime?.toDate().getFullYear();
      }
    }
  }

  onSelectYear(year: number): void {
    this.selectedYear = year;
    this.downloadStatement();
  }

  /**
   * Get the list of years for which the tax statement can be downloaded
   */
  get years(): number[] {
    const currentYear = new Date().getFullYear();

    if (!this.firstPaymentsYear) {
      return [];
    }

    // calculating the amount of years to show tax statements for
    // increasing the current year by one to be able to show the current year
    const yearsToShow = currentYear + 1 - this.firstPaymentsYear;
    const yearsArray = Array.from({ length: yearsToShow }, (v, i): number => currentYear - i);

    if (yearsArray.length) {
      this.selectedYear = yearsArray[0];
    }

    return yearsArray;
  }

  get hasInvestments(): boolean {
    return this.getTotalInvested > 0;
  }

  async downloadStatement(): Promise<void> {
    this.isGeneratingPDF = true;
    const instance = await firebaseAxiosInstance();
    const [err, res] = await to<AxiosResponse, AxiosError>(instance.get('generateDividendsPDF', {
      params: {
        lang: this.$i18n.locale,
        year: this.selectedYear,
      },
      responseType: 'blob',
    }));

    this.isGeneratingPDF = false;

    if (err) {
      let errorMessage;

      if (err.response?.status === 404) {
        errorMessage = this.$t('dashboard.errors.noInvestmentsError');
      } else {
        errorMessage = this.$t('dashboard.errors.pdfError');
      }

      this.addToastMessage({
        text: errorMessage,
        type: 'danger',
      });
    } else {
      saveAs(res!.data, `statement_${this.selectedYear}.pdf`);
    }
  }
}
