import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class AccountInfobox extends Vue {
  @Prop({ required: true }) icon!: [string, string];
  @Prop({ required: true, default: false }) warning!: Boolean;
  @Prop({ required: true, default: false }) error!: Boolean;
}
