import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ProgressBarColor extends Vue {
  @Prop({ default: 0 })
  min!: number;

  @Prop({ default: 100 })
  max!: number;

  @Prop({ default: null })
  maxLabel!: string | null;

  @Prop({ default: null })
  total!: number;

  @Prop({ default: 0 })
  progress!: number;

  @Prop({ default: 'yellow' })
  color!: string;

  @Prop({ default: '%' })
  unit!: string;

  @Prop({ default: '' })
  label!: string;

  @Prop({ default: '' })
  legendMaxDetail!: string;

  @Prop({ default: false })
  draggable!: boolean;

  draggableProgress: number | null = null;

  get draggableProgressPercentage(): number {
    return this.draggableProgress !== null ? this.draggableProgress : this.progress;
  }

  get progressPercentage(): number {
    const percentage = (100 * (this.progress - this.min)) / ((this.total || this.max) - this.min);
    return Math.min(percentage, 100);
  }

  get maxPercentage(): number {
    return (100 * (this.max - this.min)) / ((this.total || this.max) - this.min);
  }

  onDrag(event: any): void {
    this.draggableProgress = Number(event.target.value);
    this.$emit('drag', Number(event.target.value));
  }
}
