import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { Gift, Investment, Payment, PaymentType } from '@/store/models/investment';
import { capitalize } from '@/filters/string';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    Loader,
    WithDownloads,
    LazyImage,
  },
})

export default class AccountInvestments extends Vue {
  @Prop({}) investment!: Investment;

  @Getter getPaidPaymentsByInvestmentId!: Function;

  get filteredPayments(): Payment[] {
    return ([
      ...this.getPaidPaymentsByInvestmentId(this.investment.id),
    ] as Payment[]);
  }

  get asset(): Asset {
    return this.investment.asset as Asset;
  }

  get paymentData(): any {
    return this.filteredPayments.map((payment): any => ({
      type: payment.type,
      date: payment.paymentDateTime || payment.createdDateTime,
      bonds: payment.providerData.metadata.sharesAmount,
      total: payment.type === PaymentType.GiftRedeem ? 0 : payment.providerData.metadata.euroAmount,
      code: (payment.gift as Gift)?.code || '',
    }));
  }

  paymentColumns = ['type', 'date', 'bonds', 'total', 'code'];

  paymentOptions = {
    headings: {
      type: capitalize(this.$t('common.typeOfInvestment').toString()),
      date: capitalize(this.$t('common.date').toString()),
      bonds: capitalize(this.$tc(this.asset?.type === 'tree' ? 'common.tree' : 'common.bond', 2).toString()),
      total: capitalize(this.$t('common.total').toString()),
      code: capitalize(this.$t('common.giftCode').toString()),
    },
    filterable: false,
    perPage: 12,
    perPageValues: [],
    sortable: ['type', 'date', 'bonds', 'total', 'code'],
    // columnsClasses strings need to have a space at the end
    // because vue-tables-2 adds classes runtime without a space before
    columnsClasses: {
      type: 'table__col table__col--type ',
      date: 'table__col table__col--date ',
      bonds: 'table__col table__col--tree ',
      total: 'table__col table__col--total table__col--right ',
      code: 'table__col table__col--total table__col--right ',
    },
    skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    texts: {
      count: this.$t('dashboard.table.count'),
      first: this.$t('dashboard.table.first'),
      last: this.$t('dashboard.table.last'),
      filter: this.$t('dashboard.table.filter'),
      filterPlaceholder: this.$t('dashboard.table.filterPlaceholder'),
      limit: this.$t('dashboard.table.limit'),
      page: this.$t('dashboard.table.page'),
      noResults: this.$t('dashboard.table.payments.noResults'),
      filterBy: this.$t('dashboard.table.filterBy'),
      loading: this.$t('dashboard.table.loading'),
      defaultOption: this.$t('dashboard.table.defaultOption'),
      columns: this.$t('dashboard.table.columns'),
    },
  };
}
