import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { InvestmentTree } from '@/store/models/investment';
import { capitalize } from '@/filters/string';
import { firebase } from '@/firebase';
import { State as StateClass } from '@/store/models';
import { Asset } from '@/store/models/asset';

type WithoutRefs<Collection> = Omit<Collection, 'asset' | 'investor' | 'investment'>;

@Component({
  components: {
    Loader,
    WithDownloads,
    LazyImage,
  },
})

export default class AccountTrees extends Vue {
  @Prop({}) asset!: (Asset & { id: string });

  @State('user') user!: StateClass['user'];

  trees: WithoutRefs<InvestmentTree>[] = [];

  mounted(): void {
    this.getTrees();
  }

  get treesData(): any[] {
    return this.trees.map((tree): any => {
      const createdDateTime = tree.createdDateTime.toDate();
      return {
        id: tree.id,
        createdDateTime: tree.createdDateTime,
        endDateTime: createdDateTime.setFullYear(createdDateTime.getFullYear() + (this.asset.duration || 0)),
      };
    });
  }

  async getTrees(): Promise<void> {
    // Don't fetch data if it is already loaded
    if (!this.trees.length) {
      const investorRef = this.$firestore.collection('investors').doc(this.user?.id);
      const assetRef = this.$firestore.collection('assets').doc(this.asset?.id);

      try {
        const querySnapshot = await this.$firestore
          .collection('trees')
          .where('investor', '==', investorRef)
          .where('asset', '==', assetRef)
          .orderBy('createdDateTime', 'desc')
          .get() as firebase.firestore.QuerySnapshot<InvestmentTree>;

        // Filter out `deleted` trees. Since not all tree docs have this field in Firestore this has to be done client side
        this.trees = querySnapshot.docs.reduce((treesArray: InvestmentTree[], treeDoc): InvestmentTree[] => {
          if (!treeDoc.get('deleted')) {
            treesArray.push({ id: treeDoc.id, ...treeDoc.data() });
          }

          return treesArray;
        }, []);
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    }
  }

  treesColumns = ['id', 'orderDate', 'endDate'];

  treesOptions = {
    headings: {
      id: capitalize(this.$t('common.id').toString()),
      orderDate: capitalize(this.$t('common.orderDate').toString()),
      endDate: capitalize(this.$t('common.endDate').toString()),
    },
    filterable: false,
    perPage: 12,
    // perPageValues: [],
    sortable: ['id', 'orderDate', 'endDate'],
    // columnsClasses strings need to have a space at the end
    // because vue-tables-2 adds classes runtime without a space before
    columnsClasses: {
      id: 'table__col table__col--id ',
      orderDate: 'table__col table__col--orderDate ',
      endDate: 'table__col table__col--endDate ',
    },
    skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    texts: {
      count: this.$t('dashboard.table.count'),
      first: this.$t('dashboard.table.first'),
      last: this.$t('dashboard.table.last'),
      filter: this.$t('dashboard.table.filter'),
      filterPlaceholder: this.$t('dashboard.table.filterPlaceholder'),
      limit: this.$t('dashboard.table.limit'),
      page: this.$t('dashboard.table.page'),
      noResults: this.$t('dashboard.table.bonds.noResults'),
      filterBy: this.$t('dashboard.table.filterBy'),
      loading: this.$t('dashboard.table.loading'),
      defaultOption: this.$t('dashboard.table.defaultOption'),
      columns: this.$t('dashboard.table.columns'),
    },
  };
}
