import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import * as MUTATIONS from '@/store/constants';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';

@Component({
  components: {
    FormInput,
    ValidationProvider,
    ValidationObserver,
  },
})

export default class PropertyReservationModal extends Vue {
  @Prop() asset!: Asset;

  giftCode = ''
  redeemGiftCodeAgreement = false

  IdentRouteNames = IdentRouteNames

  @StateClass user!: State['user']
  @StateClass operations!: State['operations'];

  @Action redeemGiftCode!: Function
  @Action(addToastMessage) addToastMessage!: Function;

  @Getter isEligibleToInvest!: Function;

  mounted(): void {
    this.$modal.show('redeemGiftCodeModal');
  }

  handleRedeemGiftCode(): void {
    this.redeemGiftCode({
      giftCode: this.giftCode,
      userId: this.user?.id,
      email: this.user?.email,
    });
  }

  @Watch('operations')
  onOperationsChange(newOperation: any, oldOperation: any): void {
    if (oldOperation.name === MUTATIONS.REDEEM_GIFT_CODE_PROCESSING) {
      if (newOperation.name === MUTATIONS.REDEEM_GIFT_CODE_ERROR) {
        this.addToastMessage({
          text: this.$t(`dashboard.errors.${newOperation.error}`),
          type: 'danger',
        });
      }

      if (newOperation.name === MUTATIONS.REDEEM_GIFT_CODE_SUCCESS) {
        this.addToastMessage({
          text: this.$t('giftCode.redeem.success'),
          type: 'success',
        });
        this.$emit('close');
      }
    }
  }

  resetForm(): void {
    const fields = ['giftCode'];

    fields.forEach((field): void => {
      this[field] = '';
    });
  }

  get redeemingGiftCode(): boolean {
    return this.operations && this.operations.name === MUTATIONS.REDEEM_GIFT_CODE_PROCESSING;
  }
}
