import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';

@Component({
  extends: Bar,
})
export default class InvestmentProgressChart extends Vue {
  @Prop() data!: any;
  @Prop() options!: any;

  renderChart: any

  mounted(): void {
    this.renderChart(this.data, this.transformedOptions);
  }

  @Watch('data') onDataChange(newData: any): void {
    this.renderChart(this.data || newData, this.transformedOptions);
  }

  get transformedOptions(): any {
    return {
      ...this.options,
      responsive: true,
      stacked: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tti, data): string => {
            const { datasetIndex } = tti;
            const { label } = data.datasets[datasetIndex];
            // Force two decimal precision (the values are already rounded to 2 decimal precision)
            const decimal = (tti.yLabel as number).toFixed(2);
            // For Dutch locale use comma separator
            const formattedDecimal = this.$i18n.locale === 'nl' ? decimal.replace('.', ',') : decimal;
            return `${label}: € ${formattedDecimal}`;
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        gridLines: {
          drawBorder: false,
        },
        yAxes: [{
          ...!this.data?.datasets[2]?.data?.length && ({
            ticks: {
              stepSize: 1,
              max: 10,
            },
          }),
          stacked: true,
          beginAtZero: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
        }],
        xAxes: [{
          stacked: true,
          beginAtZero: true,
        }],
      },
    };
  }
}
